@import url(https://fonts.googleapis.com/css2?family=Sen&display=swap);
body {
  font-family: Sen !important;
}

.ui,
.statistic,
.value,
.label,
.item,
.list,
.header {
  font-family: Sen !important;
}

.MuiTableCell-root,
.MuiTableCellHead,
.MUIDataTableHeadCell-root > *,
.MuiDataTableCellHeadCell-data > * {
  font-family: Sen !important;
  font-size: 14px;
}

.MuiTypography-h6 {
  font-family: Sen !important;
  font-weight: bolder;
  text-transform: uppercase;
}
.MuiButton-text {
  font-family: Sen !important;
  font-weight: bolder !important;
  text-transform: uppercase;
  white-space: nowrap;
}
.apexcharts-legend-text,
.apexcharts-legend-marker,
.apexcharts-datalabels {
  font-family: Sen !important;
}

.login {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(88deg, blue, green, white);
}

.load {
  height: 100vh;
  width: 100vw;
}

.load-box{
  height:45vh; 
  width:45vw;
}

.login-form {
  min-height: 45vh;
}

.active-target {
  border-right: 2px solid green;
}

.bar-low {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
}

.ui .statistic > .label {
  white-space: nowrap !important;
}

.ui .input {
  font-family: Sen !important;
}

@media (max-width: 500px) {
  body{
    font-family: Sen !important;
  }
  .login {
    height: 100vh;
    width: 100vw;
  }
  .login-form {
    width: 80vw !important;
    margin: -15% !important;
  }
  .apexcharts-legend-text,
  .apexcharts-legend-marker,
  .apexcharts-datalabels {
    display: none !important;
  }
}

